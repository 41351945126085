import { Component, ElementRef, ViewChild, Input, Output, EventEmitter, inject } from '@angular/core';
import { LayoutService } from '../service/app.layout.service';
import { AppMenuComponent } from '../menu/app.menu.component';
import { Router, RouterLink } from '@angular/router';
import { NgClass } from '@angular/common';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { OverlayPanel } from 'primeng/overlaypanel';
import { TranslocoDirective } from '@jsverse/transloco';
import { StateService } from '@shared/service/state.service';
import { ModuleEnum } from '@shared/utils/constants';
@Component({
  selector: 'app-sidebar',
  templateUrl: './app.sidebar.component.html',
  standalone: true,
  styleUrl: './app.sidebar.component.scss',
  imports: [RouterLink, AppMenuComponent, NgClass, OverlayPanelModule, TranslocoDirective],
})
export class AppSidebarComponent {
  openSideBar = true;
  selMenu = true;
  timeout: any = null;
  public logo = '';
  @ViewChild('menuContainer') menuContainer!: ElementRef;
  @ViewChild('overlayPanel', { static: false }) overlayPanel!: OverlayPanel;
  @Output() overlayClicChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() overlayClic: boolean = false;
  moduleEnum = ModuleEnum;
  selectedModule: ModuleEnum = ModuleEnum.SEL;
  private _stateService = inject(StateService);
  private router = inject(Router);

  constructor(
    public layoutService: LayoutService,
    public el: ElementRef,
  ) {}

  checkWindowSize = () => {
    if (window.innerWidth < 1200) {
      this.openSideBar = true;
      this.layoutService.state.sidebarActive = true;
    } else {
      this.openSideBar = false;
      this.layoutService.state.sidebarActive = false;
    }
  };
  ngOnInit() {
    this.checkWindowSize();
    window.addEventListener('resize', this.checkWindowSize);
  }

  openMenu() {
    this.openSideBar = !this.openSideBar;
    this.layoutService.state.sidebarActive = !this.layoutService.state.sidebarActive;
  }

  changeEdify() {
    this.selMenu = false;
  }
  changeSEL() {
    this.selMenu = true;
  }

  navigateToModule(moduleId: ModuleEnum) {
    console.log('navigateToModule', moduleId);
    this._stateService.setSelectedMenu(moduleId);

    switch (moduleId) {
      case ModuleEnum.SEL:
        this.selectedModule = moduleId;
        this.router.navigate(['/app/sel']);
        break;
      case ModuleEnum.EDIFY:
        this.selectedModule = moduleId;
        this.router.navigate(['/app/edify']);
        break;
      case ModuleEnum.SKILLS:
        this.selectedModule = moduleId;
        this.router.navigate(['/app/abilities']);
        break;
    }
  }

  getLogoSrc(isOpen: boolean): string {
    switch (this.selectedModule) {
      case ModuleEnum.SEL:
        return isOpen ? 'assets/images/logoSELmenu.png' : 'assets/images/SEL.png';
      case ModuleEnum.EDIFY:
        return isOpen ? 'assets/images/Edify Logo.png' : 'assets/images/fft-hover.png';
      case ModuleEnum.SKILLS:
        return isOpen ? 'assets/images/Skills.png' : 'assets/images/Skills.png';
      default:
        return isOpen ? 'assets/images/default-logo.png' : 'assets/images/default-logo-small.png';
    }
  }

  getLogoAlt(): string {
    switch (this.selectedModule) {
      case ModuleEnum.SEL:
        return 'SEL';
      case ModuleEnum.EDIFY:
        return 'Edify';
      case ModuleEnum.SKILLS:
        return 'Skills';
      default:
        return 'Default';
    }
  }

  onMouseEnter() {
    if (!this.layoutService.state.anchored) {
      if (this.timeout) {
        clearTimeout(this.timeout);
        this.timeout = null;
      }
      this.layoutService.state.sidebarActive = true;
    }
  }

  onMouseLeave() {
    if (!this.layoutService.state.anchored) {
      if (!this.timeout) {
        this.timeout = setTimeout(() => (this.layoutService.state.sidebarActive = false), 300);
      }
    }
  }

  anchor() {
    this.layoutService.state.anchored = !this.layoutService.state.anchored;
  }
  onContentClick(): void {
    this.overlayClic = true;
    this.overlayClicChange.emit(this.overlayClic);
  }
}
